import React from "react";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
// images

const Header = () => {
  return (
    <header className="py-8">
      <div className="container mx-auto">
        <div className="flex md:justify-between items-center flex-col md:flex-row">
          {/* logo */}
          <p href="" className="select-none">
            Improving lives one line of code at a time ...
          </p>
          {/* Emoji's */}
          <div className="flex gap-x-4 text-3xl pt-5 md:pt-0">
            <a href="https://www.linkedin.com/in/ahmad-hawamdah-587b47105/" className="hover:scale-110 delay-50 hover:text-accent">
              <FaLinkedin />
            </a>
            <a href="https://github.com/ahmadhawamdah"  className="hover:scale-110 delay-50 hover:text-accent">
              <FaGithub />
            </a>
            <a href="https://www.instagram.com/whatsplotsla/" className="hover:scale-110 delay-50 hover:text-accent">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
